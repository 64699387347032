import { ErrorMessage, Field } from "formik";
import React, { useEffect, useRef, useState } from "react";
export default function AssameseInput(props) {
  // console.log("************ AssameseInput ", props.name, props.value);

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
    if (props.value) {
      inputRef.current.setSelectionRange(
        props.value.length,
        props.value.length
      );
    }
  }, [props.value]);

  let classNames = props.disabled ? "" : "";

  return (
    <>
      <div className=''>
        {props.label ? (
          <label
            htmlFor={props.id}
            className='block text-sm font-medium text-gray-700'
          >
            {props.label}
          </label>
        ) : (
          <span></span>
        )}
        <input
          value={props.value}
          disabled={props.disabled ?? false}
          type={"text"}
          name={props.name}
          // onChange={(e) => {
          //   props.onChange(props.name, e.target.value);
          // }}
          ref={inputRef}
          onChange={null}
          placeholder={props.placeholder}
          onClick={props.onClick}
          onFocus={props.onFocus}
          readOnly={true}
          // onBlur={props.onBlur}
          className={`font-Banikanta appearance-none mt-2 rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${classNames}`}
        />
        <ErrorMessage
          name={props.name}
          component='div'
          className='text-red-500 py-2'
        />
      </div>
    </>
  );
}
