import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import axios from "axios";

console.log(process.env.REACT_APP_API_BASE_URL);
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, body: data, params }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    // baseUrl: "http://localhost:8001",
    // baseUrl: "https://server.kareng.co.in",
  }),
  // global configuration for the api
  // refetchOnMountOrArgChange: 30,
  // refetchOnReconnect: true,
  // keepUnusedDataFor: 30,,
  tagTypes: [
    "Auth",
    "Application",
    "Certificate",
    "Course",
    "Grade",
    "DegreeMode",
    "DegreeModeCourse",
    "fee",
    "college",
    "Subject",
  ],
  endpoints: (builder) => ({}),
});
