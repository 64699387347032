import React, { useState } from "react";
import Model from "../Components/Utils/Model";
import { useNavigate } from "react-router-dom";
const FeeStructure = ({ onContinue, onCancel }) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  let navigate = useNavigate();
  return (
    <>
      <Model
        style={{ marginTop: "30px", borderRadius: 5 }}
        className='mywidth'
        // className="max-w-full px-4 mx-auto my-8 sm:px-6 lg:px-8"
      >
        <div className='container px-4 mx-auto my-3'>
          <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
          <ol className='mb-8 text-sm list-decimal list-inside'>
            <li className='mb-2'>
              Distinctly visible scanned copies of University
              Registration/Migration Card, Marksheet/Gradesheet and Admit Card
              of Final Year/ Semester examination must be uploaded in JPG/JPEG
              format.
            </li>
            {/* <li className="mb-2">
              In case of M.B.B.S. examination, digital distinctly visible copies
              of University Registration Card, Final Year Admit Card & Marksheet
              and Internship Completion Certificate must be uploaded in JPG/JPEG
              format.
            </li> */}
            {/* <li className="mb-2">
              In case of (Medical) P.G. Degree, (Medical) P.G. Diploma
              Examinations and D. Pharm digital distinctly visible copies of
              Admit Card, Marksheet, University Registration Card and
              Provisional Certificate issued from the Institution last attended
              must be uploaded in JPG/JPEG format.
            </li> */}
            <li className='mb-2'>
              FEE: For Original Certificate as shown below:
            </li>
            <div className='mb-8 overflow-x-auto'>
              <table className='table-auto '>
                <thead className='px-4 py-2 font-semibold border'>
                  <tr>
                    <th className='px-4 py-2 border'>Options</th>
                    <th className='px-4 py-2 border'>Particulars</th>
                    <th className='px-4 py-2 border'>Amount (in Rs.)</th>
                    <th className='px-4 py-2 border'>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='px-4 py-2 border'>I</td>
                    <td className='px-4 py-2 border'>Certificate Fee</td>
                    <td className='px-4 py-2 border'>850.00</td>
                    <td className='px-4 py-2 border'>
                      Applicable only for Candidates of Under Graduate Degrees
                      and the PG and Ph.D degrees who will NOT participate in
                      the Convocation Ceremony
                    </td>
                  </tr>
                  <tr>
                    <td className='px-4 py-2 border'>II</td>
                    <td className='px-4 py-2 border'>
                      Certificate & Convocation Fee
                    </td>
                    <td className='px-4 py-2 border'>1500.00</td>
                    <td className='px-4 py-2 border'>
                      Applicable for Candidates of PG and Ph.D degrees who will
                      participate in the Convocation Ceremony
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="px-4 py-2 border">II</td>
                    <td className="px-4 py-2 border">
                      If applied after two year from the date of passing
                    </td>
                    <td className="px-4 py-2 border">950/-</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">III</td>
                    <td className="px-4 py-2 border">
                      If applied after three year from the date of passing
                    </td>
                    <td className="px-4 py-2 border">1100/-</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">IV</td>
                    <td className="px-4 py-2 border">
                      If applied after four year from the date of passing
                    </td>
                    <td className="px-4 py-2 border">1250/-</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">V</td>
                    <td className="px-4 py-2 border">
                      If applied after five year from the date of passing
                    </td>
                    <td className="px-4 py-2 border">1400/-</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">VI</td>
                    <td className="px-4 py-2 border">
                      If applied after six year from the date of passing
                    </td>
                    <td className="px-4 py-2 border">1550/-</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">VII</td>
                    <td className="px-4 py-2 border">
                      If applied after seven year from the date of passing
                    </td>
                    <td className="px-4 py-2 border">1700/-</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">VIII</td>
                    <td className="px-4 py-2 border">
                      If applied after eight year from the date of passing
                    </td>
                    <td className="px-4 py-2 border">1850/-</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">IX</td>
                    <td className="px-4 py-2 border">
                      If applied after nine year from the date of passing
                    </td>
                    <td className="px-4 py-2 border">2000/-</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">X</td>
                    <td className="px-4 py-2 border">
                      If applied after ten years or more from the date of
                      passing
                    </td>
                    <td className="px-4 py-2 border">3000/-</td>
                  </tr> */}
                </tbody>
              </table>
            </div>

            {/* <li className="mb-2">
              Original Certificate may be handed over to the candidate or to
              person duly authorized by the candidate whose signature is
              attested by the candidate.
            </li> */}
            {/* <li className="mb-2">
              No certificate will be issued to the Principal/ Head of the
              Department/ Institution.
            </li>
            <li className="mb-2">
              There is provision to dispatch the certificate by Registered Post
              to the candidate concerned, if the candidate so desires.
            </li> */}
            {/* <li className="mb-2">
              FEE: For Original Certificate as shown below:
            </li> */}
          </ol>
          <p className='mb-2 font-bold text-red-600'>
            Note: Fee Option cannot be switched after the final submission of
            the online application and payment of fees.{" "}
          </p>
          <label className='inline-flex items-center text-sm'>
            <input
              type='checkbox'
              className='w-5 h-5 text-blue-600 form-checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span className='ml-2 text-gray-700'>
              I have read and understood the instruction.
            </span>
          </label>

          <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
            <button
              className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                isChecked ? "" : "opacity-50 cursor-not-allowed"
              }`}
              onClick={onCancel}
              disabled={!isChecked}
            >
              Continue
            </button>
            <button
              className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Model>
    </>
  );
};

export default FeeStructure;
