import { Formik, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import * as Yup from "yup";
import Input from "../../Components/Utils/Input";
import CustomSelect from "../../Components/Utils/CustomSelect";

const MyAccount = () => {
  const formikRef = useRef();
  const authData = useSelector((state) => state.auth);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const [editingUserId, setEditingUserId] = useState(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    gender: Yup.string().required("Gender is required"),
  });

  const handleEdit = (user) => {
    setIsEditMode(true);
    formikRef.current?.setValues({
      name: user.name,
      email: user.email,
      phone: user.phone,
      gender: user.gender,
    });
    setEditingUserId(user.id);
  };

  useEffect(() => {
    setInitialValues({
      name: authData.user?.user?.name,
      email: authData.user?.user?.email,
      phone: authData.user?.user?.phone,
      gender: authData.user?.user?.gender,
    });
  }, []);

  const handleSaveClick = () => {
    setIsEditMode(false);
  };

  const handleSubmit = async (values) => {};

  return (
    <>
      <div className='pt-20'>
        <h2 className='text-3xl font-bold mb-4 text-center text-indigo-600'>
          MY PROFILE
        </h2>
        <div>
          <div className='max-w-sm mx-auto bg-white rounded-lg overflow-hidden shadow-lg'>
            <div className='border-b px-4 pb-6'>
              <Formik
                enableReinitialize={true}
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <>
                    {isEditMode == true ? (
                      <div>
                        {/* Edit mode */}
                        <div className='mb-4'>
                          <Input
                            type='text'
                            label='Name'
                            className='text-gray-800 text-lg border rounded px-3 py-2 w-full'
                            // defaultValue={authData.user.name}
                          />
                        </div>

                        <div className='mb-4'>
                          <Input
                            type='text'
                            label='Email'
                            className='text-gray-800 text-lg border rounded px-3 py-2 w-full'
                            // defaultValue={authData.user.email}
                          />
                        </div>
                        <div className='mb-4'>
                          <Input
                            type='text'
                            label='Phone'
                            className='text-gray-800 text-lg border rounded px-3 py-2 w-full'
                            // defaultValue={authData.user.phone}
                          />
                        </div>
                        <div className='mb-4'>
                          <CustomSelect
                            options={[
                              { id: 0, name: "Male" },
                              { id: 1, name: "Female" },
                              { id: 2, name: "Other" },
                            ]}
                            name='gender'
                            label='Select your gender'
                            getOptionValue={(option) => `${option}`}
                            getOptionLabel={(option) => `${option.name} `}
                            // defaultValue={authData.user.gender}
                            onChange={(selectedOption) => {
                              formikRef.current?.setFieldValue(
                                "gender",
                                selectedOption.id
                              );
                            }}
                          />
                        </div>
                        <div className='flex gap-2 px-2'>
                          <button
                            className='flex-1 rounded-full bg-blue-600 text-white antialiased font-bold hover:bg-blue-800 px-4 py-2'
                            onClick={handleSaveClick}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {/* Display mode */}

                        <div className='mb-4'>
                          <label
                            className='block text-gray-600 text-sm font-semibold mb-2'
                            htmlFor='email'
                          >
                            Name
                          </label>
                          <p className='text-gray-800 text-lg'>
                            {authData.user?.user?.name}
                          </p>
                        </div>

                        <div className='mb-4'>
                          <label
                            className='block text-gray-600 text-sm font-semibold mb-2'
                            htmlFor='email'
                          >
                            Email
                          </label>
                          <p className='text-gray-800 text-lg'>
                            {authData.user?.user?.email}
                          </p>
                        </div>
                        <div className='mb-4'>
                          <label
                            className='block text-gray-600 text-sm font-semibold mb-2'
                            htmlFor='phone'
                          >
                            Phone
                          </label>
                          <p className='text-gray-800 text-lg'>
                            {authData.user?.user?.phone}
                          </p>
                        </div>
                        {/* <div className="mb-4">
                          <label
                            className="block text-gray-600 text-sm font-semibold mb-2"
                            htmlFor="gender"
                          >
                            Gender
                          </label>
                          <p className="text-gray-800 text-lg">{authData.user?.user?.gender =}</p>
                        </div> */}
                        {/* <div className='flex gap-2 px-2'>
                          <button
                            className='flex-1 rounded-full antialiased bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4  transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110'
                            onClick={handleEdit}
                          >
                            Edit
                          </button>
                        </div> */}
                      </div>
                    )}
                  </>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
