import React from "react";
import Drawer from "../Utils/Drawer";
import { useGetCertificatesQuery } from "../../store/slices/apis/masterApi";
import { Link } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { TbCertificate } from "react-icons/tb";
import { RiAccountPinBoxLine } from "react-icons/ri";
import { HiOutlineHome } from "react-icons/hi2";

const Sidebar = ({ open, onClose }) => {
  const {
    data: certificates = [],
    isLoading: isLoadingGetCertificates,
    isSuccess: isSuccessGetCertificates,
    isError: isErrorGetCertificates,
  } = useGetCertificatesQuery();
  return (
    <Drawer
      title=""
      visible={true}
      placement="left"
      onClose={onClose}
      style={{}}
    >
      <div>
      <SidebarMenu
          key={1}
          to={`/dashboard`}
          icon={
            <HiOutlineHome
              className="h-4 w-4 mr-1 text-blue-600"
              aria-hidden="true"
            />
          }
          label="Home"
        />
        {isSuccessGetCertificates &&
          certificates.map((certificate) => (
            // <Link
            //   to={`/certificates/create/${certificate.id}`}
            //   className="flex items-center text-sm py-4 px-2 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-300 transition duration-300 ease-in-out"
            //   data-mdb-ripple="true"
            //   data-mdb-ripple-color="primary"
            // >
            //   <span>{certificate.name}</span>
            // </Link>
            <SidebarMenu
              key={certificate.id}
              to={`/certificates/create/${certificate.id}`}
              icon={
                <TbCertificate
                  className="h-4 w-4 mr-1 text-blue-600"
                  aria-hidden="true"
                />
              }
              label={certificate.name}
            />
          ))}
        {/* <SidebarMenu
          key={1}
          to={`/dashboard/refund`}
          icon={
            <HiOutlineReceiptRefund
              className="h-4 w-4 mr-1 text-blue-600"
              aria-hidden="true"
            />
          }
          label="Apply for Refund"
        /> */}
        <SidebarMenu
          key={2}
          to={`my_account`}
          icon={
            <RiAccountPinBoxLine
              className="h-4 w-4 mr-1 text-blue-600"
              aria-hidden="true"
            />
          }
          label="My Account"
        />
      </div>
    </Drawer>
  );
};

export default Sidebar;
