import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "cropperjs/dist/cropper.min.css";
import Upload from "../../Components/Utils/Upload";
import CropImage from "../../Components/Utils/CropImage";
import {
  useUploadApplicationFileMutation,
  useUploadApplicationDocFileMutation,
  useUpdateApplicationMutation,
  useGetPendingApplicationQuery,
  useGetApplicationQuery,
  useGetSingleApplicationQuery,
} from "./../../store/slices/apis/applicationApi";
import { useGetDocumentsQuery } from "./../../store/slices/apis/masterApi";
import { toast } from "react-toastify";
import { base64StringtoFile, fileToUrl } from "../../Utils/Status";
import { IMAGE_BASE_URL } from "../../Utils/Common";
import Loader from "../../Components/Utils/Loader";
import { useParams } from "react-router-dom";

export const DocumentReupload = () => {
  const { application_id } = useParams();

  const {
    data: application,
    isLoading: isLoadingGetApplication,
    isSuccess: isSuccessGetApplication,
    isError: isErrorGetApplication,
  } = useGetSingleApplicationQuery(application_id);

  const {
    data: documents = [],
    isLoading: isGetDocumentsLoading,
    isFetching: isGetDocumentsFetching,
    isSuccess: isGetDocumentsSuccess,
  } = useGetDocumentsQuery(application?.certificate_id);

  const hardcodedDocument = {
    id: 17,
    name: "Internship Completion certificate from Assam Medical College",
    crop: 0,
  };
  const [updatedDocuments, setUpdatedDocuments] = useState(documents);

  useEffect(() => {
    let newDocuments = [...documents];

    if ([45, 46, 47, 57].includes(application?.course_id)) {
      newDocuments = [...newDocuments, hardcodedDocument];
    }

    setUpdatedDocuments(newDocuments);
  }, [documents, application]);

  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isOpenApplicantCropper, setOpenApplicantCropper] = useState(false);
  const [isOpenSignatureCropper, setOpenSignatureCropper] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadApplicationFile] = useUploadApplicationFileMutation();
  const [uploadApplicationDocFile] = useUploadApplicationDocFileMutation();
  const [uploadApplication] = useUpdateApplicationMutation();
  const handleUploadDocFile = async (event, document_id, file) => {
    if (file) {
      setLoading(true);
      try {
        if (file) {
          // Check if the file size is within the allowed limit (500 KB)
          if (file.size > 500 * 1024) {
            // File size exceeds the limit, show an error message
            alert("File size exceeds the allowed limit of 500 KB.");
            // Clear the input field
            event.target.value = null;
            return;
          }
        }

        const formData = new FormData();
        formData.append("id", application.id);
        formData.append("document_id", document_id);
        formData.append("file", file);
        const result = await uploadApplicationDocFile(formData).unwrap();
        if (result.status) {
          toast("Saving Successful");
          // setTab(4);
        } else {
          toast(result.message || "");
        }
      } catch (err) {
        toast(err?.data?.message || "");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadFile = async (name, file) => {
    if (file) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("id", application.id);
        formData.append("file", file);
        formData.append("name", name);
        const result = await uploadApplicationFile(formData).unwrap();
        if (result.status) {
          toast("Saving Successful");
        } else {
          toast(result.message || "");
        }
      } catch (err) {
        toast(err?.data?.message || "");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const result = await uploadApplication({
        id: application.id,
        step: 4,
        admin_status: 0,
        allow_re_upload: 0,
      }).unwrap();
      if (result.status) {
        toast("Saving Successful");
      } else {
        toast(result.message || "");
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {(isSaving || isLoading) && <Loader />}
      <div className='items-center justify-center min-h-full bg-white'>
        <div className='w-full p-10 rounded-lg shadow-2xl '>
          {/* APPLICANT IMAGE SECTION START */}
          <div>
            <label
              htmlFor='file'
              className='mb-2 font-bold text-gray-700 text-md'
            >
              Upload Applicant's Photo
            </label>
            <div className='flex justify-between pb-2 mb-10 border-b border-gray-500'>
              <Upload
                id='file'
                name='applicant_photo'
                type='file'
                onChange={(event) => {
                  setFile(event.currentTarget.files[0]);
                  setOpenApplicantCropper(true);
                }}
              />
              {isOpenApplicantCropper && file && (
                <CropImage
                  // initialAspectRatio={16 / 9}
                  // aspectRatio={150 / 180}
                  aspectRatio={150 / 180}
                  name='applicant_photo'
                  image={file}
                  isOpen={isOpenApplicantCropper}
                  onClose={() => {
                    setOpenApplicantCropper(false);
                    setFile(null);
                  }}
                  onUpload={(name, file) => {
                    handleUploadFile(name, file);
                  }}
                  maxWidth={500}
                />
              )}
              <div>
                {application?.applicant_photo && (
                  <img
                    src={`${IMAGE_BASE_URL}${application.applicant_photo}`}
                    alt='Applicant'
                    className='object-contain mt-1 max-h-36'
                  />
                )}
              </div>
            </div>
          </div>
          {/* APPLICANT IMAGE SECTION END */}

          {/* SIGNATURE SECTION START */}
          <div>
            <label
              htmlFor='signature'
              className='mb-1 font-bold text-gray-700 text-md'
            >
              Upload Applicant's Signature
            </label>
            <div className='flex justify-between pb-2 mb-10 border-b border-gray-500 '>
              <Upload
                id='signature'
                name='signature'
                type='file'
                onChange={(event) => {
                  setFile(event.currentTarget.files[0]);
                  setOpenSignatureCropper(true);
                }}
              />
              {isOpenSignatureCropper && file && (
                <CropImage
                  // initialAspectRatio={16 / 9}
                  aspectRatio={100 / 25}
                  name='signature'
                  image={file}
                  isOpen={isOpenSignatureCropper}
                  onClose={() => {
                    setOpenSignatureCropper(false);
                    setFile(null);
                  }}
                  onUpload={(name, file) => {
                    handleUploadFile(name, file);
                  }}
                  maxWidth={500}
                />
              )}
              <div>
                {application?.signature && (
                  <img
                    src={`${IMAGE_BASE_URL}${application.signature}`}
                    alt='Signature'
                    className='object-contain mt-1 max-h-36'
                  />
                )}
              </div>
            </div>
          </div>
          {/* SIGNATURE SECTION END */}

          {updatedDocuments
            // .filter((d) => {
            //   if (application.course_id == 40) {
            //     return d.id == 1;
            //   } else {
            //     return true;
            //   }
            // })
            .map((document) => {
              const exists = application?.documents?.find(
                (doc) => doc.document_id === document.id
              );

              return (
                <div key={document.id}>
                  <label
                    htmlFor={`file-${document.id}`}
                    className='mb-1 font-bold text-gray-700 text-md'
                  >
                    Upload {document.name}
                  </label>
                  <div className='flex justify-between pb-2 mb-10 border-b border-gray-500 '>
                    <Upload
                      id='file'
                      name={`file-${document.id}`}
                      type='file'
                      onChange={(event) =>
                        handleUploadDocFile(
                          event,
                          document.id,
                          event.currentTarget.files[0]
                        )
                      }
                    />

                    <div>
                      {exists && (
                        <img
                          src={`${IMAGE_BASE_URL}${exists.file}`}
                          alt={document.name}
                          className='object-contain mt-1 max-h-36'
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

          <hr />

          <div className='flex justify-between mt-3'>
            <button
              type='button'
              className='px-4 py-2 mr-2 text-white bg-blue-500 rounded'
              disabled={false}
              onClick={handleSubmit}
            >
              Re Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default DocumentReupload;
