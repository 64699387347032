const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  isLoading: false,
  refreshOrders: false,
  headerName: null,
  openSidebar: false,
};
const drawerSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    showLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRefreshOrders(state, action) {
      console.log("refreshOrders");
      state.refreshOrders = action.payload;
    },
    setHeaderName(state, action) {
      state.headerName = action.payload;
    },
    setOpenSidebar(state, action) {
      state.openSidebar = action.payload;
    },
  },
});

export const { showLoading, setRefreshOrders, setHeaderName, setOpenSidebar } =
  drawerSlice.actions;
export default drawerSlice.reducer;
