import React, { useState } from "react";
import Model from "../Utils/Model";
import { IMAGE_BASE_URL } from "../../Utils/Common";
const ImageModel = ({ url = "", style, onClose }) => {
  return (
    <div>
      <Model visible={true} title="" style={{ minWidth: 600, minHeight: 400 }}>
        <div>
          <button
            style={{ float: "right", marginBottom: "15px" }}
            onClick={onClose}
          >
            &#x2573;
          </button>
          <img
            src={`${IMAGE_BASE_URL}${url}`}
            alt=""
            style={{ width: "100%" }}
          />
        </div>
      </Model>
    </div>
  );
};

export default ImageModel;
