import React from "react";
import { Disclosure } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronUpIcon } from "@heroicons/react/outline";
import { useGetUserQuery } from "./../store/slices/apis/authApi";
import { useEffect } from "react";
import { setAuthenticated, setUser } from "./../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
function LandingPage() {
  // get query string here

  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const {
    data: user,
    isLoading: isGetUserLoading,
    isFetching: isGetUserFetching,
    isSuccess: isGetUserSuccess,
    isError: isGetUserError,
    error: userError,
  } = useGetUserQuery();

  useEffect(() => {
    if (isGetUserSuccess) {
      dispatch(setAuthenticated(true));
      dispatch(setUser(user || {}));
    }
    if (isGetUserError) {
    }
  }, [user, dispatch, isGetUserLoading, isGetUserSuccess, isGetUserError]);
  if (isGetUserLoading) return <div>Loading...</div>;
  if (isLoggedIn) return navigate("/dashboard");

  return (
    <>
      <Disclosure
        as='nav'
        className='flex justify-center bg-gradient-to-r to-indigo-800 from-blue-500 h-14'
      >
        <div className='flex items-center justify-center flex-1 sm:items-stretch sm:justify-start'>
          <div className='flex items-center flex-shrink-0 m-auto'>
            <img
              src='https://erp.dibru.work/images/dibru.png'
              className='w-10 mr-5 rounded-full'
              alt='Avatar'
            />

            <h1 className='text-white'>
              Online Certificate Application Portal
            </h1>
          </div>
        </div>
      </Disclosure>

      <div className='min-h-screen p-5'>
        <div className='h-full pb-5 rounded-lg shadow-2xl '>
          <div className='max-w-6xl py-8 mx-auto lg:py-12 '>
            <div className='text-center '>
              <h3 className='text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
                Welcome to the XXIII Convocation Portal of Dibrugarh University
              </h3>
              {/* <p className='my-10 text-xl text-red-500 '>
                Portal is closed now.
              </p> */}
              {/* <p className='mt-6 text-lg text-gray-600'>
                Portal for Online Application of the XXIII Convocation of
                Dibrugarh University by the eligible intending candidates
              </p> */}
            </div>

            <h1 className='mt-3 text-lg text-center text-blue-500'>
              The Submission of Online Application of the XXIII Convocation of
              Dibrugarh University by the eligible intending candidates shall
              begin from 01.11.2024. The last date for submission is 15.12.2024
              (till 11.50 P.M.). For the Ph.D. Degrees, the portal shall be open
              till 07.02.2025.
            </h1>

            <div className='p-4 mt-5 border bg-slate-50'>
              <h1 className='text-2xl font-semibold underline'>
                Before starting the application process, please ensure that you
                have the following
              </h1>
              <div className='p-5'>
                <ul className='text-lg list-decimal'>
                  <li>
                    A valid mobile number for sending Activation Code or OTP(One
                    Time Password).
                  </li>

                  <li> An active email id.</li>

                  <li>
                    Any of the following facilities for online payment of
                    application fee:
                    <ul className='pl-10 list-disc'>
                      <li>Net Banking</li>
                      <li>Debit Card (VISA, Master, Maestro, RuPay)</li>
                      <li>Credit card</li>
                    </ul>
                  </li>

                  <li>
                    <span className='text-blue-500'>
                      Relevant Documents on Digital and Distinctly Visible
                      Format.
                    </span>
                    (The file must be in{" "}
                    <span className='text-red-500'> JPG/JPEG </span> format).
                  </li>
                </ul>

                <i className='text-xl text-red-500'>
                  Please ensure that you have sufficient balance in your bank
                  account to pay the application fee online.
                </i>
              </div>
            </div>

            <div className='flex items-center justify-center mt-10 gap-x-6'>
              <Link
                to='/student-login'
                className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                LOG IN
              </Link>
              <Link
                to='/register'
                className='rounded-md bg-red-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
              >
                REGISTER
              </Link>
            </div>
          </div>

          {/* DropDown Menu */}
          <div className='w-full px-4'>
            <div className='w-full max-w-4xl p-2 mx-auto bg-white rounded-2xl'>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-lg font-medium text-left text-indigo-600 bg-indigo-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                      <span>Instructions</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className='px-4 pt-4 pb-2 text-lg text-gray-500'>
                      <ul className='space-y-2'>
                        <li>
                          <h1 className='text-base font-medium'>Step 1:</h1>
                          <p className='font-normal'>
                            Click the "Sign In" button at the top of this page.
                            If you already have an account, you can directly
                            login to the system. Otherwise, Click the Link {""}
                            <span className='text-blue-500'>Register</span> (If
                            you are a New User) and fill up the form and finally
                            click on "Register" button. You will get an
                            "Activation Code" in your mobile. Enter your Mobile
                            No and the Activation Code and click "Activate"
                            button to activate your account. You can now login
                            to the portal by entering the mobile number and
                            password that you have provided. {""}
                            <span className='text-red-500'>
                              Please remember the password for future login. You
                              can reset your password from 'Forgot Password'
                              option on Sign In Page.
                            </span>
                          </p>
                        </li>

                        <li>
                          <h1 className='text-base font-medium'>Step 3:</h1>
                          <p className='font-normal'>
                            The information about the documents that are
                            required to be uploaded/submitted will appear in a
                            POP Up after clicking the apply button. Once you are
                            sure that the information displayed/entered is
                            correct, click the "NEXT" button. Please note that
                            if the Previous Button is available you will able to
                            edit the information byclicking that button which is
                            filled by You.
                          </p>
                        </li>

                        <li>
                          <h1 className='text-base font-medium'>Step 4:</h1>
                          <p className='font-normal'>
                            Fill up the empty fields and click "NEXT". Upload
                            the required documents in Document Upload Step.
                          </p>
                        </li>

                        <li>
                          <h1 className='text-base font-medium'>Step 5:</h1>
                          <p className='font-normal'>
                            Review the form in Review and Payment Step. Check
                            the information, documents, photograph and signature
                            whether they are correctly entered and displayed. If
                            anything needs to be edited, you can do so by going
                            back to the relevant stage either through the
                            Previous button or by clicking the corresponding
                            button for that stage.
                          </p>
                        </li>

                        <li>
                          <h1 className='text-base font-medium'>Step 6:</h1>
                          <p className='font-normal'>
                            Check all the declarations and click the Submit
                            button after you have reviewed your form.
                            Application Fee Amount and other payment information
                            will be displayed. You will be required to pay your
                            Application Fee online.
                          </p>
                        </li>

                        <li>
                          <h1 className='text-base font-medium'>Step 7:</h1>
                          <p className='font-normal'>
                            Click "Proceed to Pay". Select any one of the
                            payment options available i.e Netbanking, Debit card
                            (Visa, Master, Mastero, RuPay), credit card etc. The
                            ATM-cum-Debit card option is for older versions of
                            ATM cards which is now supported only by limited
                            number of banks.
                          </p>
                        </li>

                        <li>
                          <h1 className='text-base font-medium'>Step 8:</h1>
                          <p className='font-normal'>
                            After successful submission an Acknowledgment Slip
                            with a unique transaction id will be generated.
                            Download the Acknowledgment Slip for future
                            reference. You may also download (or print) the
                            submitted Application Form from the Dashboard.
                            <br />
                            <p className='text-red-500'>
                              Please remember your application form is
                              considered to be finally submitted only when the
                              application fee is successfully paid and
                              Acknowledgment Slip is generated with "SUCCESS"
                              Status. After final submission, any information in
                              the form cannot be modified. However you can
                              download or printout the Application Form or
                              Acknowledgment Slip for future reference.
                            </p>
                          </p>
                        </li>
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as='div' className='mt-2'>
                {({ open }) => (
                  <>
                    <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-lg font-medium text-left text-indigo-600 bg-indigo-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                      <span>Technical Helpline</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className='px-4 pt-4 pb-2 text-lg text-gray-500'>
                      <p className='font-medium'>
                        In case of any difficulty in filling up the application
                        form you may contact the following number and email.
                      </p>
                      <ul className='list-disc'>
                        <li>
                          Telephone no: (For Telephonic Support Contact
                          <b className='text-blue-600'> 6003669909</b> Between
                          10:00 A.M. To 5:00 P.M. (Monday to Friday))
                        </li>

                        <li>Email: certificatesupport@dibru.ac.in</li>
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as='div' className='mt-2'>
                {({ open }) => (
                  <>
                    <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-lg font-medium text-left text-indigo-600 bg-indigo-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                      <span>Important Note</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className='px-4 pt-4 pb-2 text-lg text-gray-500'>
                      In case of any Payment Error, due to any network
                      communication/connectivity problems,
                      <span className='text-red-500'>
                        {" "}
                        if your application form does not get submitted
                        successfully, but the amount has been debited from your
                        account, please contact our technical helpline no or
                        send a mail to certificatesupport@dibru.ac.in within 6
                        hours from the time of transaction mentioning your name,
                        mobile no, date and time of transaction and bank account
                        no. from where transaction was done.
                      </span>{" "}
                      It may take 2-3 working days to confirm the transaction by
                      reconciliation with the bank and so the candidates are
                      advised not to wait till the last date for applying.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as='div' className='mt-2'>
                {({ open }) => (
                  <>
                    <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-lg font-medium text-left text-indigo-600 bg-indigo-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                      <span>Refund and cancellation Policy</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className='px-4 pt-4 pb-2 text-lg text-gray-500'>
                      Application Fee once paid will not be refunded. However,
                      if more than one payment is made for the same degree from
                      the same username, then extra amount may be refunded after
                      verification if claimed through proper procedure. For
                      claiming refund candidate needs to login to his/her
                      account and submit the "Refund Claim" form after filling
                      up all the details within 10 days from the date of such
                      transaction. It will take a few days to process the refund
                      and the amount will be credited to the source bank
                      account. Cash refunds are not available.
                      <br />
                      There is no provision for cancelling submitted application
                      and as such no refund for cancellation request.
                      <br />
                      Applicants are requested to note the transaction no. for
                      future reference and request for any type of refund will
                      be at the sole discretion of the University.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as='div' className='mt-2'>
                {({ open }) => (
                  <>
                    <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-lg font-medium text-left text-indigo-600 bg-indigo-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                      <span>Terms and Conditions</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className='px-4 pt-4 pb-2 text-lg text-gray-500'>
                      <ul className='list-disc'>
                        <li>
                          For online payments, it is important for candidates to
                          note the transaction Id for future reference.
                        </li>
                        <li>
                          No transaction charges will be charged by Dibrugarh
                          University from the students for online payment of
                          fees by any of the modes mentioned above.
                        </li>
                        <li className='text-red-500'>
                          {" "}
                          Mere submission of application and payment of
                          application fee do not make the candidate eligible for
                          Certificate. Candidates are advised to carefully read
                          the eligibility criteria in the notification before
                          applying.
                        </li>
                        <li className='text-red-500'>
                          Though all efforts have been made to ensure the
                          accuracy of the content on this website, the same
                          should not be construed as a statement of law or used
                          for any legal purposes. In case of any ambiguity or
                          doubts, users are advised to verify/check with the
                          Certificate Branch.
                        </li>
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as='div' className='mt-2'>
                {({ open }) => (
                  <>
                    <Disclosure.Button className='flex justify-between w-full px-4 py-2 text-lg font-medium text-left text-indigo-600 bg-indigo-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                      <span>Privacy Policy</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className='px-4 pt-4 pb-2 text-lg text-gray-500'>
                      We do not collect personal information about you without
                      your knowledge. However, during your visit, our system may
                      record non-personal information such as the type of
                      browser & operating system used, IP address, date & time
                      of the visit and the web pages that you visit on our site.
                      We use this information for statistical analysis, to help
                      us make our site more useful to visitors. This tracking
                      system does not record information about individuals. On
                      certain pages, we use "cookies" for making transaction.
                      The cookies do not collect information about you, but only
                      about your browser "session." Information provided on the
                      online form are used only for the purposes for which it is
                      intended and is not made available to any unauthorized
                      party. We don't collect debit card/credit card information
                      in our system; it is governed by the policy of the Payment
                      gateway Provider. Our site may contain link to other sites
                      which are beyond our Privacy Policy. Therefore, you are
                      advised to read Privacy Policy statement of external sites
                      before proceeding with any kinds of transactions.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
