import React from "react";
import { Link } from "react-router-dom";
import * as HIcons from "@heroicons/react/outline";
import { useDispatch } from "react-redux";
import { setOpenSidebar } from "../../store/slices/utilSlice";
function SidebarMenu(props) {
  // const TheIcon = icons[props.icon];
  const dispatch = useDispatch();

  return (
    <Link
      to={props.to}
      className="flex items-center text-sm py-4 px-2 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-300 transition duration-300 ease-in-out"
      href="#!"
      data-mdb-ripple="true"
      data-mdb-ripple-color="primary"
      onClick={() => dispatch(setOpenSidebar(false))}
    >
      {/* <TheIcon className="h-4 w-4 mr-1 text-blue-600" aria-hidden="true" /> */}
      {props.icon}
      <span>{props.label}</span>
    </Link>
  );
}

export default SidebarMenu;
