// import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
// import Alert from "../Utils/Alert";
import Header from "./Header";

const Layout = () => {
  // const { message } = useSelector((state) => state.message);

  return (
    <>
      <div className='min-h-ful'>
        <Header />
        <main className='w-full' style={{ paddingTop: "70px" }}>
          {/* <SideBar /> */}
          {/* <section className="w-full"> */}
          {/* {message && <Alert message={message} />} */}
          {/* </section> */}
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default Layout;
