import { useDispatch } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import { useHistory } from "react-router-dom";
export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signin: builder.mutation({
      query: (payload) => ({
        url: `/student/login`,
        method: "POST",
        body: payload,
        withCredentials: true,
      }),
      invalidatesTags: ["Auth"],
    }),
    signup: builder.mutation({
      query: (payload) => ({
        url: `/student/register`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {},
    }),
    changePassword: builder.mutation({
      query: (payload) => ({
        url: `/student/change-password`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {},
    }),
    resendOtp: builder.mutation({
      query: (payload) => ({
        url: `/student/resend-otp`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["AuthResendOtp"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {},
    }),
    passwordReset: builder.mutation({
      query: (payload) => ({
        url: `/student/forgot-password`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["AuthPasswordReset"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {},
    }),
    verifyAccount: builder.mutation({
      query: (payload) => ({
        url: `/student/verify`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {},
    }),
    logout: builder.mutation({
      query: () => ({
        url: `/student/logout`,
        method: "POST",
      }),
      // invalidatesTags: ["Auth","Cart","Consumer", "Order", "Address"],
    }),
    getUser: builder.query({
      query: () => ({
        url: `/student`,
        method: "GET",
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
});

export const {
  useSigninMutation,
  useSignupMutation,
  useChangePasswordMutation,
  useResendOtpMutation,
  usePasswordResetMutation,
  useLogoutMutation,
  useGetUserQuery,
  useVerifyAccountMutation,
} = authApi;
