const { createSlice, current } = require("@reduxjs/toolkit");
const initialState = {
  isLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  user: {},
  panel: "student",
};  
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated(state, action) {
      state.isLoggedIn = action.payload;
    },
     logout(state, action) {
      state.isLoggedIn = false;
    },
    setAuthDrawer(state, action) {
      state.authDrawer = action.payload;
    },
    
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const { setAuthenticated, logout, setUser } = authSlice.actions;
export default authSlice.reducer;
