import React from "react";

const Model = ({
  children,
  visible = false,
  title,
  width = 400,
  onClose,
  style,
  className,
}) => {
  // ${className}
  return (
    <div className="model">
      <div className={`dialog`} style={{ ...style }}>
        {/* <div className="content">
            <div className="header">  
                <h5>Create New Item</h5>
                <button className="close" onClick={() => onClose(false)}>
                &times; 
                </button>
            </div>
            </div> */}
        {children}
      </div>
    </div>
  );
};

export default Model;
