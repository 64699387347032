import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

export default function PaymentReceipt() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [payment, setPayment] = useState();

  // useEffect(() => {
  //   async function checkData() {
  //     if (id && id > 0) {
  //       setLoading(true);

  //       console.log(" call 2");
  //       const _payment = await BaseApi().get(`payments/${id}`);

  //       setPayment(_payment.data);
  //       setLoading(false);
  //     }
  //   }
  //   checkData();
  // }, [id]);

  return (
    <>
      {loading == true && (
        <>
          <div className="flex">
            <span className="m-auto">
              <ThreeDots
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"
              />
            </span>
          </div>
        </>
      )}

      {payment != null && (
        <div className="flex flex-wrap ">
          <div className="md:w-full pr-4 pl-4  ">
            <div className=" ">
              <div className="flex-auto p-6">
                <div className="sm:w-full pr-4 pl-4">
                  <div className="container    max-w-full mx-auto sm:px-4">
                    <div className="panel panel-default" id="printableArea">
                      <div className="panel-body">
                        <table className="w-full table-auto  border-collapse border border-slate-400">
                          <tbody>
                            <tr>
                              <td className="table-data" width="20%">
                                <img
                                  src="{{$college_information->collegelogo}}"
                                  alt="profile Pic"
                                  height="80"
                                  width="80"
                                />
                              </td>
                              <td width="60%">
                                <p>College Name</p>
                                <p>Address</p>
                              </td>
                              <td width="20%">
                                <button
                                  className="inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded py-1 px-3 leading-normal no-underline no-print float-right bg-teal-500 text-white hover:bg-teal-600 btn-md screen-only  "
                                  onClick="window.print()"
                                >
                                  Print
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="100%">
                          <tr>
                            <td colSpan="3">
                              <br />
                              <center>
                                <span>PAYMENT RECEIPT</span>
                              </center>
                              <br />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              Received From:
                              <font color="blue"> Diganta Kumar Das</font>
                              <span className="float-right">
                                Receipt No:asdasd
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              Programme : asdsad
                              <span className="float-right">
                                Session: adasd
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              Semester : asd
                              <span className="float-right">
                                Payment Date: asd
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2"></td>
                          </tr>
                        </table>

                        <table className="w-full max-w-full mb-4 bg-transparent table-bordered table-condensed">
                          <tr>
                            <td>#</td>
                            <td>Particulars</td>
                            <td>Rs.</td>
                          </tr>

                          {payment.payment_details.map(
                            (payment_detail, index) => {
                              return (
                                <>
                                  <tr>
                                    <td align="center">{++index}</td>
                                    <td>{payment_detail.id}</td>

                                    <td align="center">amount</td>
                                  </tr>
                                </>
                              );
                            }
                          )}

                          <tr>
                            <td colSpan="2">
                              <b>Total Amount</b>
                            </td>
                            <td>
                              <b>asd</b>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <b>Paid</b>
                            </td>
                            <td align="center">
                              <b>paid</b>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="3">
                              <b>(Rupees Only Paid)</b>
                            </td>
                          </tr>
                        </table>

                        <div> </div>
                        <div>
                          {" "}
                          Payment Mode : type| Ref. No : challan_no| Tracking ID
                          : bank_name
                        </div>

                        <br />
                        <div className="md:w-3/5   sm:w-3/5 pr-4 pl-4">
                          <p> This is a computer generated payment receipt.</p>
                        </div>
                        <div className="md:w-full pr-4 pl-4">
                          <br />
                          <div className="flex flex-wrap ">
                            <div className="text-left md:w-1/2 pr-4 pl-4">
                              <br />
                              <br />
                              <br />
                              <p>Dibrugarh University</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
