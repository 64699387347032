import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.min.css";
import Model from "./Model";
import { base64StringtoFile } from "../../Utils/Status";
const CropImage = ({
  title = "",
  name = "",
  image,
  isOpen,
  onClose,
  setFieldValue,
  modelStyle,
  style,
  onUpload,
  aspectRatio
}) => {
  const cropperRef = useRef(null);
  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    const dataUrl = cropper.getCroppedCanvas()?.toDataURL();
    // setFieldValue(name, dataUrl);
    onUpload(name, base64StringtoFile(dataUrl, "signature"));
    onClose();
  };
  const handleCancelCrop = () => {
    onClose();
    // setFieldValue(name, null);
  };
  // const width = 150; // Desired width in pixels
  // const height = 180; // Desired height in pixels
  // const aspectRatio = width / height;
  return (
    <div>
      <Model visible={true} title="" style={{ ...modelStyle }}>
        <div>
          <label
            htmlFor="file"
            className="text-sm font-medium text-gray-700 mb-1"
          >
            {title}
          </label>
          {image && (
            <div>
              <div>
                <label className="text-sm font-medium text-gray-700 mb-1">
                  Document Preview
                </label>
                <Cropper
                  ref={cropperRef}
                  src={URL.createObjectURL(image)}
                  style={{ ...style }}
                  guides={true}
                  cropBoxResizable={false}
                  center={true}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  aspectRatio={aspectRatio}
                  // minCropBoxHeight={50}
                  // minCropBoxWidth={50}
                  // cropBoxMovable={false}
                  // preview={}
                  // aspectRatio={1}
                  // aspectRatio={16 / 9}
                  // aspectRatio={NaN}
                  // viewMode={1}
                  // minCropBoxHeight={10}
                  // minCropBoxWidth={10}
                  // dragMode="none"
                  // action="move"
                  // autoCropArea={1}
                  // movable={false}
                  checkOrientation={false}
                />
                <button
                  type="button"
                  className="bg-blue-500 text-white py-2 px-4 rounded mr-2 mt-5"
                  onClick={handleCrop}
                >
                  Crop
                </button>
                <button
                  type="button"
                  className="bg-green-500 text-white py-2 px-4 rounded mr-2 mt-5"
                  onClick={handleCancelCrop}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </Model>
    </div>
  );
};

export default CropImage;
