import { apiSlice } from "./apiSlice";
export const applicationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addApplication: builder.mutation({
      query: (payload) => ({
        url: `/application`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Application"],
    }),
    getApplication: builder.query({
      query: (id) => ({
        url: `/application/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Application"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {},
    }),
    getPendingApplication: builder.query({
      query: (id) => ({
        url: `/application/pending/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Application"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {},
    }),
    getSingleApplication: builder.query({
      query: (id) => ({
        url: `/application/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["SingleApplication"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {},
    }),
    updateApplication: builder.mutation({
      query: (payload) => ({
        url: `/application/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Application"],
    }),
    uploadApplicationFile: builder.mutation({
      query: (payload) => ({
        url: `/application/upload/${payload.get("id")}`,
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: payload,
      }),
      invalidatesTags: ["Application"],
    }),
    uploadApplicationDocFile: builder.mutation({
      query: (payload) => ({
        url: `/application/upload/document/${payload.get("id")}`,
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: payload,
      }),
      invalidatesTags: ["Application"],
    }),
    getApplications: builder.query({
      query: () => ({
        url: "/application",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Application"],
    }),
    deleteApplication: builder.mutation({
      query: (id) => ({
        url: `/application/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Application"],
    }),
    getAppliedCertificates: builder.query({
      query: () => ({
        url: "/application/certificate/applied",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Application"],
    }),
    getCertificateFee: builder.query({
      query: (id) => ({
        url: `/application/certificate/fee/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Application"],
    }),
    getDataByRegNo: builder.query({
      query: (query) => {
        console.log(query);
        return {
          url: `/application/convlist/search?course_id=${query.course_id}&reg_no=${query.debounceQuery}`,
          method: "GET",
        };
      },
      transformResponse: (response) => response.data,
    }),
    searchRegNo: builder.query({
      query: (query) => {
        console.log(query);
        return {
          url: `/application/convlist/search-reg-no?course_id=${query.course_id}&reg_no=${query.search_term}`,
          method: "GET",
        };
      },
      transformResponse: (response) => response.data,
    }),
  }),
});
export const {
  useAddApplicationMutation,
  useUpdateApplicationMutation,
  useGetApplicationQuery,
  useGetPendingApplicationQuery,
  useGetSingleApplicationQuery,
  useUploadApplicationFileMutation,
  useUploadApplicationDocFileMutation,
  useGetAppliedCertificatesQuery,
  useGetCertificateFeeQuery,
  useLazyGetDataByRegNoQuery,
  useLazySearchRegNoQuery,
} = applicationApi;
