import React, { useEffect, useRef, useState } from "react";
import { CertificateCreateStep1 } from "./CertificateCreateStep1";
import { CertificateCreateStep2 } from "./CertificateCreateStep2";
import { CertificateDocUpload } from "./CertificateDocUpload";
import { CertificateReviewPayment } from "./CertificateReviewPayment";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { BsCloudUpload } from "react-icons/bs";
import { VscSmiley } from "react-icons/vsc";
import "./CertificateCreate.css";
import {
  useGetApplicationQuery,
  useGetPendingApplicationQuery,
} from "../../store/slices/apis/applicationApi";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FeeStructure from "../FeeStructure";

export default function CertificateCreate() {
  const [page, setPage] = useState(1);
  const [openFeesModal, setOpenFeesModal] = useState(true);
  const { certificate_id } = useParams();
  // const [applicationId, setApplicationId] = useState(null);
  const handleTabChange = (page) => {
    setPage(page);
  };

  const {
    data: application,
    isLoading: isLoadingGetApplication,
    isSuccess: isSuccessGetApplication,
    isError: isErrorGetApplication,
  } = useGetPendingApplicationQuery(certificate_id);
  const headerName = useSelector((state) => state?.util.headerName);
  useEffect(() => {
    if (isSuccessGetApplication) {
      // setApplicationId(application?.id);
      setPage(application?.step || 1);
    }
  }, [
    application,
    isLoadingGetApplication,
    isSuccessGetApplication,
    isErrorGetApplication,
  ]);
  const effect = "bg--500";
  return (
    <>
      {openFeesModal && !application && (
        <FeeStructure
          onCancel={() => {
            setOpenFeesModal(false);
          }}
        />
      )}
      <div className='min-h-screen bg-gray-100'>
        <div className='overflow-scroll bg-white rounded-md shadow-md'>
          <div className='flex items-center justify-between px-4 py-3 bg-gray-200'>
            <h2 className='text-lg font-medium text-gray-800'>
              Application For {headerName}
            </h2>
            <div className='flex items-center'>
              <span className='mr-1 text-sm font-medium text-gray-500'>
                Step {page} of 4
              </span>
              <div className='flex items-center justify-center w-6 h-6 text-white bg-green-500 rounded-full'>
                {page}
              </div>
            </div>
          </div>
          <div className='progress_bar'>
            <div
              className={`steps ${page === 1 ? effect : ""}`}
              // onClick={() => setPage(1)}
            >
              <AiOutlineUser className='icons' />
              <span className='title'>Step 1</span>
            </div>
            <div
              className='progress_line'
              style={{
                borderColor: page >= 2 ? "rgb(59 130 246)" : "transparent",
              }}
            />
            <div
              className={`steps ${page === 2 ? effect : ""}`}
              // onClick={() => setPage(2)}
            >
              <HiOutlineClipboardDocumentList className='icons' />
              <span className='title'>Step 2</span>
            </div>
            <div
              className='progress_line'
              style={{
                borderColor: page >= 3 ? "rgb(59 130 246)" : "transparent",
              }}
            />

            <div
              className={`steps ${page === 3 ? effect : ""}`}
              // onClick={() => setPage(3)}
            >
              {/* <span className="loader "></span> */}
              <BsCloudUpload className='icons' />
              <span className='title '>Documents Upload</span>
            </div>
            <div
              className='progress_line'
              style={{
                borderColor: page >= 4 ? "rgb(59 130 246)" : "transparent",
              }}
            ></div>
            <div
              className={`steps ${page === 4 ? effect : ""}`}
              // onClick={() => setPage(4)}
            >
              <VscSmiley className='icons' />
              <span className='title'>Review Payment</span>
            </div>
          </div>
          <div className='p-4'>
            {page === 1 && (
              <CertificateCreateStep1
                data={application}
                certificate_id={certificate_id}
                setPage={setPage}
                setTab={handleTabChange}
              />
            )}
            {page === 2 && (
              <CertificateCreateStep2
                data={application}
                setTab={handleTabChange}
              />
            )}
            {page === 3 && (
              <CertificateDocUpload
                application={application}
                certificateId={certificate_id}
                setTab={handleTabChange}
              />
            )}
            {page === 4 && (
              <CertificateReviewPayment
                data={application}
                certificateId={certificate_id}
                setTab={handleTabChange}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
