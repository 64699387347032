import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import { useState,useEffect } from 'react/cjs/react.development';
import { useDispatch, useSelector } from "react-redux";
// import { postLoginAsync } from "../../../Redux/AuthSlice";
import { Formik, Form } from "formik";
// const formikRef = useRef();

import * as Yup from "yup";
// import messageSlice from "../../../Redux/MessageSlice";
import { toast } from "react-toastify";
import Input from "../../Components/Utils/Input";
import Button from "../../Components/Utils/Button";
import { usePasswordResetMutation } from "../../store/slices/apis/authApi";
// import axios from "axios";

export default function ForgetPassword() {
  const [initialValues, setInitialValues] = useState();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordReset] = usePasswordResetMutation();
  const formikRef = useRef();

  useEffect(() => {
    console.log(isLoggedIn);
    if (isLoggedIn) {
      console.log("sd");
      // navigate("/dashboard");
      // window.location.reload();
    }
    setInitialValues({
      phone: "",
      otp: "",
      new_password: "",
      confirm_password: "",
      step: 1,
    });

    // setIsOtpSent(true);
    // setIsOtpVerified(true);
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    phone: Yup.string().required("This field is required!"),
    new_password: Yup.string(),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("new_password"), null],
      "New Password and Confirm Password Must Match"
    ),
  });
  const handleSubmit = async (payload) => {
    setLoading(true);
    try {
      const result = await passwordReset(payload).unwrap();
      if (result.status) {
        if (result.step == 2) {
          toast.success("OTP Has been sent to your phone number!");
          setIsOtpSent(true);
          formikRef.current.setFieldValue("step", 2);
        }
        if (result.step == 3) {
          toast.success("OTP Verifies1!");
          setIsOtpVerified(true);
          formikRef.current.setFieldValue("step", 3);
        }
        if (result.step == 4) {
          toast.success("Password reset successfullt!");
          setIsOtpVerified(true);
          formikRef.current.setFieldValue("step", 4);
          navigate("/student-login");
        }
      } else {
        toast.error("Something went wrong!");
      }
    } catch (err) {
      console.log("error ", err);
      toast.error(err.data.message);
    } finally {
      // resetForm();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <div className='flex items-center justify-center min-h-full px-4 py-12 mt-10 sm:px-6 lg:px-8'>
        <div className='w-full max-w-2xl p-10 space-y-8 rounded-lg shadow-2xl'>
          <div>
            <img
              className='w-auto h-12 mx-auto rounded-full'
              src='https://erp.dibru.work/images/dibru.png'
              alt='Logo'
            />

            <h2 className='mt-6 text-lg font-extrabold text-center text-gray-600'>
              Dibrugarh University XXIII Convocation Portal
            </h2>
            <h2 className='mt-1 font-medium text-center text-gray-600 text-md'>
              Password Reset
            </h2>
          </div>

          {initialValues && (
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='-space-y-px '>
                  <div className=''>
                    {isOtpSent != true && (
                      <Input
                        readOnly={isOtpSent ? true : false}
                        showInput={isOtpSent ? "show" : "hide"}
                        label='Registered Contact Number'
                        name='phone'
                        placeholder='Enter your registered Contact Number'
                      />
                    )}
                  </div>
                </div>

                {isOtpSent == true && (
                  <>
                    <div className='-space-y-px '>
                      <div className=''>
                        {isOtpVerified != true && (
                          <Input
                            showInput={isOtpSent ? "show" : "hide"}
                            readOnly={isOtpVerified ? true : false}
                            label='OTP'
                            name='otp'
                            type='number'
                            placeholder='Enter the OTP'
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {isOtpVerified == true && (
                  <>
                    <div className='-space-y-px '>
                      <div className=''>
                        <Input
                          label='New Password'
                          type='password'
                          name='new_password'
                          placeholder='New Password'
                        />
                      </div>
                    </div>

                    <div className='-space-y-px '>
                      <div className=''>
                        <Input
                          type='password'
                          label='Confirm Password'
                          name='confirm_password'
                          placeholder='Confirm Password'
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className='mt-5 text-left'>
                  <Button
                    value={loading ? "Submitting..." : "Submit"}
                    icon={"SaveIcon"}
                    type='submit'
                  />
                  <span className='mr-5'></span>
                </div>
              </Form>
            </Formik>
          )}
        </div>
      </div>
    </>
  );
}
