import React from "react";
import { MutatingDots } from "react-loader-spinner";

const Loader = () => {
  return (
    // <div className="overlay">
    <div className='flex items-center justify-center'>
      <MutatingDots
        height='100'
        width='100'
        color='#1F45FC'
        secondaryColor='#1F45FC'
        radius='12.5'
        ariaLabel='mutating-dots-loading'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
      />
    </div>
    // </div>
  );
};

export default Loader;
