import { ErrorMessage } from "formik";
import React, { useState, useRef, useEffect } from "react";
import { useLazySearchRegNoQuery } from "../../store/slices/apis/applicationApi";
import { BsEmojiSmileUpsideDown } from "react-icons/bs";

function SearchableDropdown(props) {
  const [selected, setSelected] = useState(
    props.value?.length > 0 ? true : false
  );
  const [dataNotFound, setDataNotFound] = useState(false);
  let classNames = props.readOnly ? "bg-gray-100" : "";

  const options = props.options;
  // console.log(options);
  // options.map((element) => {
  //   // console.log(part);
  //   element.name =
  //     element.full_name +
  //     (element.designation?.name != undefined
  //       ? "[" + element.designation?.name + "]"
  //       : "");
  //   return element;
  // });
  const [searchTerm, setSearchTerm] = useState(props?.value ?? "");
  // console.log(searchTerm);
  // const [selectedOption, setSelectedOption] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  const [
    searchRegNo,
    {
      data: regNoData,
      isFetching: isFetchingGetRegNoData,
      isLoading: isLoadingGetRegNoData,
      isSuccess: isSuccessGetRegNoData,
      isError: isErrorGetRegNoData,
    },
  ] = useLazySearchRegNoQuery();

  useEffect(() => {
    const handleClickOutside = (event) => {
      // console.log(dropdownRef);
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
        if (selected == false && searchTerm.length > 0) {
          // console.log("searchterm", searchTerm.length);
          // console.log("selected", selected);
          handleClear();
        }
      }
    };
    let blurTimeout;
    const handleBlur = () => {
      blurTimeout = setTimeout(() => {
        setShowOptions(false);
        handleClear(); // Clearing the search and selected option
        if (selected == false && searchTerm.length > 0) {
          // console.log("searchterm", searchTerm.length);
          // console.log("selected", selected);
          handleClear();
        }
      }, 200); // Adjust the delay as needed
    };

    document.addEventListener("mousedown", handleClickOutside);

    const inputElement = dropdownRef.current.querySelector("input"); // Get the input element
    inputElement.addEventListener("blur", handleBlur); // Listen for the blur event on the input

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      inputElement.removeEventListener("blur", handleBlur);
      clearTimeout(blurTimeout);
    };
  }, [dropdownRef, searchTerm, selected]);

  const handleSearch = async (e) => {
    // setSearchTerm(e.target.value);
    // setShowOptions(true);

    // props.setSelectedOption("");
    // setSelected(false);

    const value = e.target.value;
    setSearchTerm(value);

    if (value.length >= 3) {
      try {
        const response = await searchRegNo({
          search_term: value,

          course_id: props.course_id,
        });

        const data = response.data?.map((item) => item.RgNo);
        if (data.length == 0) {
          setDataNotFound(true);
        } else {
          setDataNotFound(false);
        }
        props.updateSuggestions(data); // Pass suggestions to the parent component
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      props.updateSuggestions([]); // Clear suggestions if the search term is less than 3 characters
    }

    setShowOptions(true);
    props.setSelectedOption("");
    setSelected(false);
  };

  const handleClear = () => {
    setSearchTerm("");
    props.setSelectedOption("");
    setShowOptions(false);
    setSelected(false);
  };

  const handleSelect = (option) => {
    props.setSelectedOption(option);
    setSearchTerm(option);
    setShowOptions(false);
    setSelected(true);
  };

  useEffect(() => {
    if (props.value?.length > 0) {
      handleSelect(props.value);
    }
  }, [props.value]);

  const filteredOptions = options.filter((option) => {
    // console.log(option);

    return (
      option
        .toString()
        .toLowerCase()
        .indexOf(searchTerm.toString().toLowerCase()) !== -1
    );
  });

  return (
    <div className=''>
      <label
        htmlFor={props.id}
        className='block text-sm font-medium text-gray-700'
      >
        {props.label}
      </label>
      <div ref={dropdownRef}>
        <div className='relative'>
          <input
            readOnly={selected}
            disabled={selected}
            type='text'
            value={searchTerm}
            onChange={handleSearch}
            placeholder='Enter atleast 3 character to search'
            className={`appearance-none mt-2 rounded-none   block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${classNames}`}
          />
          {isFetchingGetRegNoData && "Searching..."}
          {dataNotFound && "Data not found"}
          {searchTerm.length > 0 && (
            <button
              type='button'
              className='absolute top-0 right-0 m-2'
              onClick={handleClear}
            >
              x
            </button>
          )}
        </div>
        {showOptions && searchTerm.length > 2 && (
          <ul className='absolute z-10 rounded-md bg-white shadow-xl'>
            {filteredOptions.map((option) => (
              <li
                key={option}
                onClick={() => handleSelect(option)}
                className='p-2 hover:bg-gray-200'
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
      <ErrorMessage
        name={props.name}
        component='div'
        className='text-red-500 py-2'
      />
    </div>
  );
}

export default SearchableDropdown;
