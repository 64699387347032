import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Layout from "../Components/Common/Layout";
function ProtectedRoute() {
  const authData = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (authData.isLoggedIn) {
    }
  }, [authData]);
  return authData.isLoggedIn ? <Layout /> : <Navigate to="/" />;
}
export default ProtectedRoute;
