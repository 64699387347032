import { object } from "yup";

const CertificateDeliveryType = Object.freeze();
function base64StringtoFile(base64String, filename = "") {
  try {
    let arr = base64String.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  } catch (error) {
    console.error("Error converting base64 string to file:", error);
    return null;
  }
}
function getFileExtensionFromBase64(base64String) {
  const dataUrlParts = base64String.split(";");
  const mimeType = dataUrlParts[0].split(":")[1];
  const fileExtension = mimeType.split("/")[1];
  return fileExtension;
}
function convertImageUrlToBase64(imageUrl) {
  return fetch(imageUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64String = reader.result;
          resolve(base64String.split(",")[1]);
        };
        reader.onerror = () => {
          reject(new Error("Failed to convert image to base64"));
        };
      });
    })
    .catch((error) => {
      console.error("Error converting image to base64:", error);
    });
}
function fileToUrl(file) {
  try {
    const blob = new Blob([file]);
    const url = URL.createObjectURL(blob);
    return url;
  } catch (error) {
    console.error("Error converting file to URL:", error);
    return null;
  }
}
function convertFileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

async function convertFileToDataUrl2(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    try {
      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
}
const PaymentStatus = Object.freeze({
  UNPAID: 0,
  PAID: 1,
  REFUND: 2,
});
const ApplicationStatus = Object.freeze({
  DRAFT: 0,
  PENDING: 1,
  VERIFIED: 2,
  REJECTED: 3,
  PRINTED: 4,
  ISSUED: 5,
  ISSUE_REJECTED: 6,
  READY: 7,
  DELIVERED: 8, 
});
const getApplicationStatus = (status) => {
  let name = "";
  switch (status) {
    case ApplicationStatus.PENDING:
      name = "Pending";
      break;
    case ApplicationStatus.VERIFIED:
      name = "Verfied";
      break;
    case ApplicationStatus.REJECTED:
      name = "Rejected";
      break;
    case ApplicationStatus.PRINTED:
      name = "Printed";
      break;
    case ApplicationStatus.ISSUED:
      name = "Issued";
      break;
    case ApplicationStatus.ISSUE_REJECTED:
      name = "Issue Rejected";
      break;
    case ApplicationStatus.READY:
      name = "Ready";
      break;
      case ApplicationStatus.DELIVERED:
        name = "Delivered";
        break;
    default:
      name = "";
  }
  return name;
};
export {
  CertificateDeliveryType,
  base64StringtoFile,
  convertImageUrlToBase64,
  fileToUrl,
  convertFileToDataUrl,
  PaymentStatus,
  ApplicationStatus,
  getApplicationStatus,
};
