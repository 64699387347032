import * as HIcons from "@heroicons/react/outline";

const Button = (props) => {
  const { ...icons } = HIcons;
  const TheIcon = icons[props.icon];
  let classNames = props.classNames
    ? props.classNames
    : "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500";
  return (
    <>
      <button
        disabled={props.disabled ?? false}
        type={props.type ?? "submit"}
        className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-full text-white  focus:outline-none focus:ring-2 focus:ring-offset-2  items-center ${classNames}`}
        onClick={props.onClick}
      >
        <span>{props.value}</span>
        <TheIcon className="h-4 w-4 ml-1" aria-hidden="true" />
      </button>
    </>
  );
};

export default Button;
