import React from "react";
import { useGetAppliedCertificatesQuery } from "../../store/slices/apis/applicationApi";
import { getApplicationStatus } from "../../Utils/Status";
import { Link } from "react-router-dom";
import { MdDashboardCustomize } from "react-icons/md";

export const AppliedCertificate = () => {
  const {
    data: appliedCertificates = [],
    isLoading: isLoadingGetAppliedCertificate,
    isSuccess: isSuccessGetAppliedCertificates,
    isError: isErrorGetAppliedCertificates,
  } = useGetAppliedCertificatesQuery();

  const handleStatusCertificate = (certificate) => {
    // handle print certificate logic here
    console.log("Printing certificate:", certificate.name);
  };

  return (
    <>
      <div className='flex flex-col justify-between gap-4 p-4 bg-gray-100 shadow-2xl sm:flex-row'>
        <Link
          to='/dashboard'
          className='flex items-center gap-2 text-xl font-medium'
        >
          <MdDashboardCustomize className='mt-1 text-blue-600' /> Applied
          Certificates
        </Link>
        {/* <Link
          to="/applied-certificate"
          className="px-4 py-2 font-medium text-white transition duration-300 ease-in-out transform bg-blue-500 rounded-full hover:bg-blue-700 hover:-translate-y-1 hover:scale-110"
        >
          View Applied Certificate
        </Link> */}
      </div>
      <div className='max-w-4xl px-4 py-8 mx-auto sm:px-6 lg:px-8'>
        {appliedCertificates.map((certificate) => (
          <div
            key={certificate.id}
            className='mb-4 bg-white rounded-lg shadow-md'
          >
            <div className='flex items-center px-6 py-4'>
              <div className='ml-4'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  {certificate.certificate_name} for {certificate.course_name}
                </h3>
                <p className='text-sm font-medium leading-5 text-gray-500'>
                  Status:
                  {(certificate?.admin_status === 0 ||
                    certificate?.admin_status === 1 ||
                    certificate?.admin_status === 2 ||
                    certificate?.admin_status === 3) &&
                    "Pending"}
                  {certificate?.admin_status == -1 && "Rejected"}
                  {certificate?.admin_status == 4 && "Issued"}
                  {certificate?.notesheet_status == "Ready"
                    ? " - Ready to Collect"
                    : ""}
                  {certificate?.admin_status == 5 ? " - Ready to Collect" : ""}
                  {certificate?.admin_status == 6 ? " - Delivered" : ""}
                </p>
                {/* link to reupload */}
                {certificate?.admin_status == -1 && (
                  <>
                    {certificate?.rejected_remarks} <br />
                  </>
                )}
                {certificate?.admin_status == -1 &&
                  certificate?.allow_re_upload == 1 && (
                    <Link
                      to={`/document-reupload/${certificate?.id}`}
                      className='text-red-500'
                    >
                      Click here to reupload documents
                    </Link>
                  )}
              </div>
              <Link
                to={`/applied-certificates/${certificate.id}`}
                className='px-4 py-2 ml-auto text-white bg-blue-500 rounded'
                onClick={() => handleStatusCertificate(certificate)}
              >
                View
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
