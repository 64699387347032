import React from "react";
import { Link } from "react-router-dom";

export default function NoPage() {
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8  mt-4">
      <div className="max-w-md w-full space-y-8 shadow-2xl p-10 rounded-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-600">
            404 <br />
            Page Not Found !
          </h2>
          <br />
          <hr /> <br />
          <h2 className="mt-1 text-center text-xl font-medium text-gray-600">
            <Link to="/">Back to Home</Link>
          </h2>
        </div>
      </div>
    </div>
  );
}
