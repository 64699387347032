import React from "react";
import { Route } from "react-router-dom";
import StudentChangePassword from "../Pages/Student/ChangePassword";
import StudentDashboard from "../Pages/Dashboard/StudentDashboard";
import ProtectedRoute from "../Middleware/ProtectedRoute";
import CertificateCreate from "../Pages/Certificate/CertificateCreate";
import MyAccount from "../Pages/Student/MyAccount";
import { AppliedCertificate } from "../Pages/Certificate/AppliedCertificate";
import ApplicantDetail from "../Pages/Certificate/ApplicantDetail";
import PaymentReceipt from "../Pages/Certificate/PaymentReceipt";
import PaymentResponse from "../Pages/PaymentResponse";
import DocumentReupload from "../Pages/Certificate/DocumentReupload";

export default (
  <>
    <Route key='student' element={<ProtectedRoute />}>
      <Route path='/dashboard' element={<StudentDashboard />} />
      <Route
        path='/certificates/create/:certificate_id'
        element={<CertificateCreate />}
      />
      <Route path='/change-password' element={<StudentChangePassword />} />
      <Route path='/my_account' element={<MyAccount />} />
      <Route path='/applied-certificates' element={<AppliedCertificate />} />
      <Route
        path='/applied-certificates/:application_id'
        element={<ApplicantDetail />}
      />
      <Route path='/payment-receipt' element={<PaymentReceipt />} />
      <Route
        path='/document-reupload/:application_id'
        element={<DocumentReupload />}
      />
    </Route>
    <Route path='/gateway/response' element={<PaymentResponse />} />
  </>
);
