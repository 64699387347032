import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/apis/apiSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import authReducer from "./slices/authSlice";
import utilReducer from "./slices/utilSlice";
import axios from "axios";
axios.defaults.withCredentials = true;
const store = configureStore({
  reducer: {
    auth: authReducer,
    util: utilReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
setupListeners(store.dispatch);
export default store;
